import React from 'react'
import { motion } from "framer-motion"

function Card2({ data }) {
    let { img, tag, label, para, i } = data;
    const textApperTopto = {
        initial: {
            y: -100,
            opacity: 0,
            // scale: 1.5
        },
        animate: {
            transition: {
                duration: 1.5
            },
            y: 0,
            // scale: 1,
            opacity: 1,
        }
    }
    return (
        <>
            {/* md:h-[20.625rem]  */}
            <div className={`flex w-full flex-col items-center gap-8  mt-16  ${i % 2 == 0 ? "md:flex-row-reverse" : "md:flex-row"}`}>
                <div className="left w-full md:w-1/2 h-full">
                    <img src={img} alt="" className='w-full  object-contain' />

                </div>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={textApperTopto}
                    className={`right pt-12 w-full md:w-1/2`}>
                    {/* <div className={`right pt-12 ${i % 2 == 0 ? "md:pr-16" : "md:pr-16"} w-full md:w-1/2`}> */}
                    <motion.p className='DecimalBook text-xs text-[#070606]'>
                        {tag}
                    </motion.p>
                    <motion.h3 className='National2CompresedXbold text-5xl text-red-500'>
                        {label}
                    </motion.h3>
                    <motion.p className='BasierSquareRegular text-xl text-[#070606] xl:w-7/12'>
                        {para}
                    </motion.p>
                </motion.div>

            </div>
        </>
    )
}

export default Card2