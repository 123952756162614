import React, { useRef, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Button from './Button';
import Loader from './Loader';
import PopUP from './PopUP';
import { logo } from '../asset/staticData';
import emailjs from '@emailjs/browser';


function Footer() {
  const location = useLocation();
  const navigate = useNavigate();
  const form = useRef();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [isLoaderOn, setIsLoaderOn] = useState(false)
  const [popUpData, setPopUpData] = useState({
    label: "",
    type: ""
  })


  const sendEmail = (e) => {
    e.preventDefault();
    console.log();
    // const usPhoneReg = /^\+?1?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    // // const usPhoneReg=/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    // if (!usPhoneReg?.test(e.target.elements.phone.value)) {
    //     // if (`${e.target.elements.phone.value}`.length != 10) {
    //     setIsPopUpOpen(true)
    //     setIsPopUpOpenText("Please Enter Correct Phone Number!!")
    //     return;
    // }
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailReg?.test(e.target.elements.email.value)) {
      setIsPopUpOpen(true)
      setPopUpData({
        label: "Please Enter Correct Email",
        type: "err"
      })
      return;
    }


    setIsLoaderOn(true)
    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      // .sendForm('service_yqxv2f8', 'template_vf0cdmn', form.current, {
      //     publicKey: 'tJm2YCnRH4F67MJpe',
      // })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsPopUpOpen(true)
          setPopUpData({
            label: "Joined Newsletter Successfully!!",
            type: ""
          })
          // setIsPopUpOpenText("Email Sent Successfully!!")
          // alert("Email Sent Successfully!!")
          e.target.reset();
          setIsLoaderOn(false)
        },
        (error) => {
          console.log('FAILED...', error.text);
          setIsPopUpOpen(true)
          setPopUpData({
            label: "Something Went Wrong!!",
            type: "err"
          })
          e.target.reset()
          setIsLoaderOn(false)
        },
      );
  };
  return (
    <>
      {isLoaderOn && <Loader />}
      {isPopUpOpen && <PopUP data={{ ...popUpData, setIsPopUpOpen }} />}

      <div className="banner6 flex gap-20 relative flex-col md:flex-row xl:gap-28 bg-[#1C0326] py-20 padding-x mt-20">
        <div className='absolute bottom-6 right-10  h-[8.75rem] w-[8.75rem] z-0'>
          <svg xmlns="http://www.w3.org/2000/svg" className='h-full w-full' viewBox="0 0 139.34 139.89">
            <g id="Group_538" data-name="Group 538" transform="translate(-1479.37 -2348.65)">
              <path id="Path_1430" data-name="Path 1430" d="M1487.09,2414.93a9.745,9.745,0,0,1-1.51-.13c-4-.66-6.89-4.03-6.07-9.01a8.9,8.9,0,0,1,2.6-4.98l2.15.35v.02a16.907,16.907,0,0,0-1.76,2.13,6.513,6.513,0,0,0-1.1,2.83,5.321,5.321,0,0,0,4.53,6.51,7.107,7.107,0,0,0,1.11.09c2.62,0,4.68-1.64,5.2-4.771a8.622,8.622,0,0,0-1.07-5.639v-.02l2.17.36a9.592,9.592,0,0,1,.76,5.76c-.73,4.45-3.61,6.5-7.01,6.5" fill="#330949" />
              <path id="Path_1431" data-name="Path 1431" d="M1496.23,2398.63l-12.76-6.37,3.27-6.53c1.07-2.15,2.52-3.2,4.19-3.2a5.253,5.253,0,0,1,2.34.62,4.183,4.183,0,0,1,2.44,5.09l6.34-1.25-1.13,2.26-5.83,1.05-2.16,4.31,4.24,2.13-.94,1.89m-5.27-13.87a2.876,2.876,0,0,0-.71.09,3.011,3.011,0,0,0-1.82,1.78l-2.3,4.59,5.12,2.56,2.3-4.59a3.066,3.066,0,0,0,.33-2.53,3.108,3.108,0,0,0-1.51-1.55,3.188,3.188,0,0,0-1.41-.35" fill="#330949" />
              <path id="Path_1432" data-name="Path 1432" d="M1504.55,2383.87l-10.37-9.79,7.93-8.4,1.45,1.25-6.54,6.94,2.91,2.75,5.39-5.7,1.39,1.31-5.38,5.71,3.27,3.09,6.78-7.19,1.32,1.39-8.15,8.64" fill="#330949" />
              <path id="Path_1433" data-name="Path 1433" d="M1515.89,2372.719l-7.06-12.389,5.74-3.27a8.05,8.05,0,0,1,3.95-1.18,5.509,5.509,0,0,1,1.57.22,7.034,7.034,0,0,1,4.23,3.59,7.158,7.158,0,0,1,.94,5.49,7,7,0,0,1-3.63,4.27l-5.74,3.269m2.38-14.809a5.587,5.587,0,0,0-2.77.82l-3.88,2.21,5.16,9.07,3.88-2.21c2.78-1.58,3.47-4.04,1.78-7-1.1-1.92-2.53-2.89-4.17-2.89" fill="#330949" />
              <path id="Path_1434" data-name="Path 1434" d="M1536.76,2363.75c-3.69,0-6.39-2.24-7.13-6.08-.85-4.42,1.57-7.93,6.23-8.83a9.98,9.98,0,0,1,1.92-.19c3.69,0,6.39,2.25,7.13,6.08.85,4.42-1.56,7.94-6.22,8.83a10.1,10.1,0,0,1-1.93.19m.89-13.15a7.82,7.82,0,0,0-1.41.14,5.58,5.58,0,0,0-3.75,2.26,6.216,6.216,0,0,0,1.51,7.96,5.078,5.078,0,0,0,2.91.84,7.009,7.009,0,0,0,1.41-.14,5.749,5.749,0,0,0,2.23-10.21,5,5,0,0,0-2.9-.85" fill="#330949" />
              <path id="Path_1435" data-name="Path 1435" d="M1569.06,2365.35l-10.49-2.631,3.46-13.83,2.06.51-2.99,11.94,8.52,2.13-.56,1.881" fill="#330949" />
              <path id="Path_1436" data-name="Path 1436" d="M1582.99,2372.82l-10.3-5.9,7.1-12.37,10.02,5.75-.89,1.7-8.26-4.74-2,3.47,6.8,3.91-.95,1.66-6.8-3.9-2.24,3.9,8.56,4.919-1.04,1.6" fill="#330949" />
              <path id="Path_1437" data-name="Path 1437" d="M1595.74,2382.9a7.643,7.643,0,0,1-5.45-2.63c-3.29-3.48-3.01-7.7.04-10.58a7.544,7.544,0,0,1,5.18-2.15,8.039,8.039,0,0,1,5.85,2.73,8.978,8.978,0,0,1,2.52,5.16l-1.58,1.5-.01-.02a8.774,8.774,0,0,0-2.33-5.34,5.821,5.821,0,0,0-4.21-2,5.693,5.693,0,0,0-3.9,1.68c-2.29,2.17-2.52,5.219-.1,7.78a5.581,5.581,0,0,0,3.41,1.88,4.187,4.187,0,0,0,.5.03,4.44,4.44,0,0,0,2.93-1.2l-4.04-4.28,1.3-1.23,5.5,5.81c-.16.179-.36.37-.67.66a7.17,7.17,0,0,1-4.94,2.2" fill="#330949" />
              <path id="Path_1438" data-name="Path 1438" d="M1603.53,2400.87l-.97-1.99,2.79-3.08-3.34-6.89-4.14.29-.95-1.96,15.33-1.05,1.61,3.31-10.33,11.37m7.52-12.58-7.03.48,2.68,5.54,4.75-5.2-.4-.82" fill="#330949" />
              <path id="Path_1439" data-name="Path 1439" d="M1606.07,2415.33l-1.77-10.67,14.07-2.33.34,2.09-12.13,2.01,1.43,8.66-1.94.24" fill="#330949" />
              <path id="Path_1440" data-name="Path 1440" d="M1491.74,2460.15l-6.05-10.21,12.26-7.26,5.89,9.93-1.61,1.05-4.85-8.2-3.46,2.04,4,6.75-1.65.969-3.99-6.74-3.87,2.29,5.03,8.5-1.7.88" fill="#330949" />
              <path id="Path_1441" data-name="Path 1441" d="M1505.63,2473.02a6.785,6.785,0,0,1-4.49-2.19,13.356,13.356,0,0,1-3.62-5.53l1.51-1.61.01.01a13.139,13.139,0,0,0,3.45,5.71,4.635,4.635,0,0,0,2.94,1.52,1.818,1.818,0,0,0,1.36-.59c.92-.98.48-1.98-1.65-4.86-1.9-2.59-3.02-4.73-1.28-6.59a3.748,3.748,0,0,1,2.8-1.19,6.929,6.929,0,0,1,4.58,2.15,11.476,11.476,0,0,1,3.01,4.72l-1.5,1.61-.02-.02a10.543,10.543,0,0,0-2.93-4.97,4.57,4.57,0,0,0-2.89-1.48,1.686,1.686,0,0,0-1.29.55,1.532,1.532,0,0,0-.19,1.89,21.484,21.484,0,0,0,1.87,2.87c1.93,2.61,3.06,4.75,1.21,6.73a3.926,3.926,0,0,1-2.88,1.27" fill="#330949" />
              <path id="Path_1442" data-name="Path 1442" d="M1518.36,2482.24l-1.91-.97,5.57-10.97-5.07-2.57.96-1.71,11.9,6.04-.81,1.78-5.07-2.57-5.57,10.97" fill="#330949" />
              <path id="Path_1443" data-name="Path 1443" d="M1539.24,2488.54a10.429,10.429,0,0,1-1.72-.15l-6.5-1.12,2.41-14.051,6.5,1.121a6.554,6.554,0,0,1,5.86,8.21,7.141,7.141,0,0,1-2.75,4.84,6.253,6.253,0,0,1-3.8,1.15m-4.05-13.071-1.76,10.271,4.39.76a7.666,7.666,0,0,0,1.22.11c2.47,0,4.1-1.49,4.6-4.38.57-3.36-.9-5.47-4.06-6.011l-4.39-.75" fill="#330949" />
              <path id="Path_1444" data-name="Path 1444" d="M1561.99,2488.48l-.47-1.79,3.75-5.12c2.09-2.8,3.08-3.99,2.68-5.5a2.1,2.1,0,0,0-2.21-1.6,4.524,4.524,0,0,0-1.14.16,8.288,8.288,0,0,0-4.71,3.7l-.02.01-.54-2.07a8.481,8.481,0,0,1,4.82-3.44,7.576,7.576,0,0,1,1.91-.26,3.737,3.737,0,0,1,3.92,2.8c.59,2.26-.58,3.75-2.89,6.8l-2.78,3.78,8.08-2.12.47,1.79-10.87,2.86" fill="#330949" />
              <path id="Path_1445" data-name="Path 1445" d="M1581.93,2480.94c-2.4,0-4.51-1.55-6-4.09-2.16-3.66-1.92-7.54,1.91-9.79a6.638,6.638,0,0,1,3.4-1.01c2.4,0,4.55,1.54,6.04,4.07,2.16,3.67,1.81,7.61-1.91,9.8a6.771,6.771,0,0,1-3.44,1.02m-.93-12.93a4.432,4.432,0,0,0-2.26.66c-2.5,1.48-2.74,4.13-1,7.07,1.22,2.07,2.78,3.23,4.48,3.23a4.415,4.415,0,0,0,2.25-.67c2.51-1.48,2.74-4.12,1.01-7.07-1.22-2.07-2.79-3.22-4.48-3.22" fill="#330949" />
              <path id="Path_1446" data-name="Path 1446" d="M1596.32,2471.84l-1.33-1.29.56-6.32c.33-3.48.56-5.01-.56-6.1a2.059,2.059,0,0,0-1.44-.61,3.107,3.107,0,0,0-2.17,1.12,8.261,8.261,0,0,0-2.11,5.61l-.02.01-1.53-1.48a8.456,8.456,0,0,1,2.34-5.44,5.421,5.421,0,0,1,3.78-1.92,3.606,3.606,0,0,1,2.52,1.06c1.68,1.63,1.45,3.51,1.05,7.32l-.41,4.67,5.81-6,1.33,1.29-7.82,8.08" fill="#330949" />
              <path id="Path_1447" data-name="Path 1447" d="M1607.8,2457.44l-1.75-.97.01-.02a13.872,13.872,0,0,0,3.78-4.05c1.15-2.07.98-3.91-.45-4.7a1.972,1.972,0,0,0-.95-.26c-1.2,0-2.47,1.23-3.92,3.85l-.36.64-.99-.55-1.47-6.81-3.9,7.05-1.59-.97,5.24-9.46,1.46.81,1.35,6.73c1.41-2.18,2.91-3.38,4.46-3.38a3.339,3.339,0,0,1,1.65.45c2.44,1.35,2.76,4.39,1.1,7.4a14.212,14.212,0,0,1-3.67,4.24" fill="#330949" />
              <path id="Path_1448" data-name="Path 1448" d="M1545.6,2432.78c-18.63,0-27.65-11.81-27.65-26.55,0-14.59,10.27-26.83,28.45-26.83a31.857,31.857,0,0,1,19.21,6.3v7.85h-.07a60.769,60.769,0,0,0-8.58-4.99,23.7,23.7,0,0,0-10.71-2.27c-12.61,0-20.45,8.5-20.45,19.94,0,10.62,6.32,18.46,17.61,19.53v1.54a23.544,23.544,0,0,0,2.7.15,26.609,26.609,0,0,0,4.91-.45v-1.53a32.836,32.836,0,0,0,14.52-6.7h.07v7.92a34.6,34.6,0,0,1-20.01,6.09" fill="#330949" />
              <path id="Path_1449" data-name="Path 1449" d="M1574.83,2455.56h-31.42v-19.03c.71.02,1.44.05,2.2.05a40.162,40.162,0,0,0,5.41-.36v12.31h23.81v7.03m-28.72-28.11a23.544,23.544,0,0,1-2.7-.15v-23.05h7.61V2427a26.609,26.609,0,0,1-4.91.45" fill="#330949" />
            </g>
          </svg>

        </div>
        <div className="left w-full md:w-2/6">
          <h3 className='National2CompresedXbold text-center md:text-right text-red-500 text-6xl underline underline-offset-8 decoration-[#CDCFE5]'>
            A BETTER <br /> TOMORROW <br /> STARTS TODAY.
          </h3>
        </div>
        <div className="right w-full md:w-4/6 relative z-10">
          <p className='BasierSquareRegular text-2xl text-white'>
            Facing financial legal issues? It’s time to fight back. Get in touch now and we will fight for you. Credo Legal is fiercely committed to defending and protecting your rights.
          </p>
          <div className='mt-10 flex flex-col sm:flex-row items-center justify-between'>

            <Button data={{
              label: "Get in Touch",
              onClickFunc: () => {
                navigate("../ContactUs")
              }
            }}>

            </Button>
            <Link to={"../Services"} className="DecimalMedium mt-4 sm:mt-0 text-sm xl:text-base  underline text-white">
              View Our Services
            </Link>
          </div>
        </div>
      </div >
      <div className='padding-x py-9 w-full bg-[#F3F4F9] banner7 '>
        <div className="flex justify-between md:flex-row flex-col items-center border-b-2  border-[#070606]">

          <div className="left flex items-center md:w-2/6">

            {logo}
          </div>
          <div className='md:w-4/6 flex justify-between'>


            <div className="navlinks flex items-center justify-between gap-3 md:gap-9 mb-4 md:mb-0 w-full md:w-fit">
              {/*  */}
              <Link className={`DecimalBook text-xs md:text-base ${location?.pathname.toLowerCase() == "/" ? "text-red-500" : "text-[#070606]"}`} to={"/"}   >
                Home
              </Link>
              <Link className={`DecimalBook text-xs md:text-base ${location?.pathname.toLowerCase() == "/about" ? "text-red-500" : "text-[#070606]"}`} to={"/About"}>
                About
              </Link>
              <Link className={`DecimalBook text-xs md:text-base ${location?.pathname.toLowerCase() == "/services" ? "text-red-500" : "text-[#070606]"}`} to={"/Services"}>
                Services
              </Link>
              <Link className={`DecimalBook text-xs md:text-base ${location?.pathname.toLowerCase() == "/faq" ? "text-red-500" : "text-[#070606]"}`} to={"/FAQ"}>
                FAQ
              </Link>
              <Link className={`DecimalBook text-xs md:text-base ${location?.pathname.toLowerCase() == "/contactus" ? "text-red-500" : "text-[#070606]"}`} to={"/ContactUs"}>
                Contact Us
              </Link>
            </div>
            <div className='hidden md:block'></div>
          </div>
        </div>
        <div className="middle py-6 border-b-2  border-[#070606] md:flex-row flex-col flex items-center">
          <div className="left md:w-2/6">
            <p className='BasierSquareRegular text-center text-sm text-[#8B8481]'>
              {/* Office: (212) 920-1247
              <br /> */}
              support@credolegal.com
              <br />
              2151 W Hillsboro Blvd, Deerfield Beach, Fl 33442
            </p>
          </div>
          <div className="right w-full md:w-4/6 md:flex-row flex-col mt-4 md:mt-0 flex items-center">
            <p className='DecimalMedium text-red-500 text-base md:mr-9 text-nowrap'>
              Join our newsletter
            </p>
            <form className="serchbox flex border-2 border-red-500 w-full" ref={form} onSubmit={sendEmail}>
              <input type="text" name='email' className='w-full h-full BasierSquareMedium text-base text-[#A19C97] py-4 px-6' placeholder='Enter Your Email*' />
              <button className='h-[3.375rem] w-[3.375rem] flex flex-shrink-0 items-center justify-center text-white bg-red-500'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>

              </button>
            </form>
          </div>
        </div>
        <div className="bottom py-6 flex items-center md:flex-row flex-col">
          <div className='hidden md:block md:w-2/6'>

          </div>
          <div className='md:w-4/6 md:flex-row flex-col gap-3 flex justify-between items-center'>
            <p className='BasierSquareRegular text-xs '>
              © 2024 Credo Legal. All Rights Reserved.
            </p>
            <div className='flex gap-3 md:gap-5 items-center'>
              <Link to={'/term-of-service'} className='DecimalBook text-xs'>
                Terms of Service
              </Link>
              <Link to={'privacy-policy'} className='DecimalBook text-xs'>
                Privacy Policy
              </Link>
              <Link to={'cookie-policy'} className='DecimalBook text-xs'>
                Cookie Policy
              </Link>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Footer