import React from 'react'

function Card4({ data }) {
    let { label, para } = data
    return (
        <>
            <div className='md:w-full hoverScale md:px-4 bg-white lg:w-2/3  flex md:flex-row flex-col gap-5 border-t border-[#070606] py-9'>
                <div className="left md:w-1/3">
                    <p className='National2CompresedXbold text-[2.5rem] text-center md:text-left text-[#CDCFE5]'>
                        {label}
                    </p>
                </div>
                <div className="right md:w-2/3">
                    <p className='BasierSquareRegular text-center md:text-left text-2xl text-[#070606]'>
                        {para}
                    </p>
                </div>

            </div>
        </>
    )
}

export default Card4