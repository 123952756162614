import React from 'react'

function Services() {
  return (
    <>
      <div className="banner11 flex flex-col items-center mt-[2rem] md:mt-[4rem] ">
        <h1 className='block text-center  text-[#1C0326] National2CompresedXbold text-6xl md:text-7xl xl:text-[5.125rem] leading-normal'>
          OUR <span className='px-3  bg-red-500 text-wrap text-white'> SERVICES</span>
        </h1>
        <p className='text-center DecimalBook text-xl mt-8 text-[#1C0326]'>
          Working with Credo Legal is easy and straightforward.
        </p>
        <p className='text-center mt-5 BasierSquareRegular text-base md:w-1/2 text-[#1C0326]'>
          Our services are designed to support you throughout your journey to freedom, and our team will be at your side to help with any questions or concerns.
        </p>
      </div>
      <div className="banner12 mt-11 ">

        {/* card1 */}
        <div className="card6 grid md:grid-cols-2 xl:grid-cols-3 gap-6 py-9 padding-x bg-[#F7F8FB]">

          <div className="left flex flex-col items-center md:col-span-2 xl:col-span-1 ">
            <h3 className='National2CompresedXbold text-5xl md:text-6xl text-red-500'>
              STRATEGIZE
            </h3>
            <img src={require('../images/drive-download-20240222T095234Z-001/Image 1.png')} alt="" className='mt-5 md:w-[19rem]' />

          </div>
          <div className="middle">
            <p className='text-base text-[#070606] BasierSquareRegular'>
              While working with Credo Legal, you’ll collaborate closely with our team, who will thoroughly evaluate your case, identify specific violations, and craft a personalized strategy for you.
            </p>
            <br />
            <p className='text-base text-[#070606] BasierSquareRegular'>
              This strategy serves as a guide for our team as they take steps to address your debts, manage all communication with your creditors, safeguard your consumer rights, and represent you in court. Consider us your dedicated legal.
            </p>

          </div>
          <div className="right">
            <p className='text-base text-[#070606] BasierSquareRegular'>
              support for anything pertaining to your personal financial affairs. We'll maintain regular communication with you, and our client portal will provide you with the capability to upload and review all case documents while tracking your progress. Feel free to engage with an attorney at any point to address your questions.

            </p>
          </div>

        </div>


        {/* card2 */}
        <div className="card6 flex flex-col xl:flex-row-reverse   grid-flow-col-dense  md:grid-cols-2 xl:grid-cols-3 gap-6 py-9 padding-x ">

          <div className="pic w-full xl:w-1/3">
            <div className="left flex flex-col items-center md:col-span-2 xl:col-span-1  ">
              <h3 className='National2CompresedXbold text-5xl md:text-6xl text-red-500'>
                SAFEGUARD
              </h3>
              <img src={require('../images/drive-download-20240222T095234Z-001/Image 2.png')} alt="" className='mt-5 md:w-[19rem]' />

            </div>
          </div>
          <div className="para flex gap-6 flex-col md:flex-row-reverse xl:w-2/3">
            <div className="middle">
              <p className='text-lg text-[#070606] BasierSquareSemibold'>
                Harassment or unfair collection practices include:
              </p>
              <ul className='pl-4 '>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Harassing phone calls.
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Use of profanity by third-party debt collectors
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Calls outside of the hours of 8 AM and 9 PM
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Exceeding 7 calls within 7 consecutive days
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Threatening legal action with no intent
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Failure to disclose as a debt collector
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Impersonating a government official or law enforcement
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Contacting friends and family for information
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Making knowledge of the debt publicly available
                </li>
              </ul>
              <br />
              <p className='text-base text-[#070606] BasierSquareRegular'>
                We take these violations seriously and initiate the building of a case against third-party debt collectors, pursuing legal action for their infractions. In each lawsuit for a violation, clients can be awarded up to $1,000.
              </p>

            </div>
            <div className="right">
              <p className='text-base text-[#070606] BasierSquareRegular'>
                We assume control of all communication with your creditors and third-party debt collectors and manage your mail. Serving as your protective shield, we hold creditors accountable for any violations of FDCPA, FCBA, FCRA, and TILA requirements.
              </p>
              <br />
              <p className='text-base text-[#070606] BasierSquareRegular'>
                The Fair Debt Collection Practices Act (FDCPA) exists to shield consumer rights, protecting individuals from harassment and unfair collection practices.
              </p>
            </div>
          </div>



        </div>



        {/* card3 */}

        <div className="card6 grid md:grid-cols-2 xl:grid-cols-3 gap-6 py-9 padding-x bg-[#F7F8FB]">

          <div className="left flex flex-col items-center md:col-span-2 xl:col-span-1 ">
            <h3 className='National2CompresedXbold text-5xl md:text-6xl text-red-500'>
              VALIDATE & NEOTIATE
            </h3>
            <img src={require('../images/drive-download-20240222T095234Z-001/Image 3.png')} alt="" className='mt-5 md:w-[19rem]' />

          </div>
          <div className="middle">
            <p className='text-base text-[#070606] BasierSquareRegular'>
              Our legal experts will challenge the validity of your debt, compelling creditors to substantiate its legality. In the event of valid debt, we will represent you, engaging in assertive negotiations with the opposing counsel to secure improved terms.
            </p>
            <br />
            <p className='text-lg text-[#070606] BasierSquareSemibold'>
              Types of Debt:
            </p>
            <ul className='pl-4 '>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Credit Cards
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Medical Bills
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Unsecured Loans
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Payday Loans
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Judgments
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Repossessions
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Collection Accounts
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Utility Bills
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Past Due Rent
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Business Accounts under the SSN
              </li>
              <li className='text-base text-[#070606] BasierSquareRegular'>
                Accounts with Legal Action
              </li>
            </ul>


          </div>
          <div className="right">
            <p className='text-lg text-[#070606] BasierSquareSemibold'>
              Understanding Validation:
            </p>
            <p className='text-base text-[#070606] BasierSquareRegular'>
              Our legal experts will challenge the validity of your debt, compelling creditors to substantiate its legality. In the event of valid debt, we will represent you, engaging in assertive negotiations with the opposing counsel to secure improved terms.
            </p>
            <br />
            <p className='text-base text-[#070606] BasierSquareRegular'>
              The Fair Debt Collection Practices Act (FDCPA) grants every American the right to dispute erroneous debt by submitting an appeal to the collector within 30 days of a collection request.
            </p>
            <br />
            <p className='text-base text-[#070606] BasierSquareRegular'>
              A collection request can be initiated through phone or writing but must distinctly identify the creditor and the owed amount. If the initial contact is by phone, the collector must provide written notification within 5 days. Acting promptly is crucial to avoid missing the window of opportunity.
            </p>
          </div>

        </div>




        {/* card4 */}
        <div className="card6 flex flex-col xl:flex-row-reverse   grid-flow-col-dense  md:grid-cols-2 xl:grid-cols-3 gap-6 py-9 padding-x ">

          <div className="pic w-full xl:w-1/3">
            <div className="left flex flex-col items-center md:col-span-2 xl:col-span-1  ">
              <h3 className='National2CompresedXbold text-5xl md:text-6xl text-red-500'>
                DEFEND
              </h3>
              <img src={require('../images/drive-download-20240222T095234Z-001/Image 4.png')} alt="" className='mt-5 md:w-[19rem]' />

            </div>
          </div>
          <div className="para flex gap-6 flex-col md:flex-row-reverse xl:w-2/3">
            <div className="middle">
              <p className='text-lg text-[#070606] BasierSquareSemibold'>
                Additionally, we extend our legal support to cover a range of financial-related products, such as:
              </p>
              <ul className='pl-4 '>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Bank accounts
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Credit cards
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Loan offerings or any other consumer financial product
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Financial obligations not tied to the security of an asset
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Any other aspect impacting the financial well-being of a consumer
                </li>

              </ul>


            </div>
            <div className="right">
              <p className='text-base text-[#070606] BasierSquareRegular'>
                Our team of professionals will stand as your representatives in court in the event of any lawsuits arising from enrolled debts, regardless of the number of legal actions you may face.
              </p>
              <br />
              <p className='text-lg text-[#070606] BasierSquareSemibold'>
                Our legal services encompass various areas, including:
              </p>
              <ul className='pl-4 '>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Unsecured debt
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Extended credit lines
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Payday loans
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Personal identity theft
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Credit reporting agencies
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Credit monitoring agencies
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Agencies involved in credit inquiries
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Bank seizures
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Asset seizure
                </li>
                <li className='text-base text-[#070606] BasierSquareRegular'>
                  Wage garnishment activities
                </li>

              </ul>
            </div>
          </div>



        </div>
      </div>
    </>
  )
}

export default Services