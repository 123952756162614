import React, { useRef, useState } from 'react'
import img5 from "../images/contactus-rev.jpg"
import InputBox from '../components/InputBox'
import { contactformData, stampLogo } from '../asset/staticData'
import Button from '../components/Button'
import emailjs from '@emailjs/browser';
import Loader from '../components/Loader';
import PopUP from '../components/PopUP';
import banner16Img from "../images/banner16-removebg-preview.png"
import toast from 'react-hot-toast'
function Contact() {
  const form = useRef();
  const [isPopUpOpen, setIsPopUpOpen] = useState(false)
  const [isLoaderOn, setIsLoaderOn] = useState(false)
  const [popUpData, setPopUpData] = useState({
    label: "Email Sent Succesfully!!",
    type: ""
  })


  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);
    const usPhoneReg = /^\+?1?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    // const usPhoneReg=/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
    if (e.target.elements.name.value == "") {
      // if (`${e.target.elements.phone.value}`.length != 10) {
      setIsPopUpOpen(true)
      setPopUpData({
        label: "Please Eanter Your Name",
        type: "err"
      })
      return;
    }
    const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailReg?.test(e.target.elements.email.value)) {
      setIsPopUpOpen(true)
      setPopUpData({
        label: "Please Eanter Correct Email",
        type: "err"
      })
      return;
    }

    if (!usPhoneReg?.test(e.target.elements.phone.value)) {
      // if (`${e.target.elements.phone.value}`.length != 10) {
      setIsPopUpOpen(true)
      setPopUpData({
        label: "Please Eanter Correct Phone Number",
        type: "err"
      })
      return;
    }

    if (e.target.elements.resolveState.value == "none") {
      // if (`${e.target.elements.phone.value}`.length != 10) {
      setIsPopUpOpen(true)
      setPopUpData({
        label: "Please Choose One Option",
        type: "err"
      })
      return;
    }


    setIsLoaderOn(true)
    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID_CONTACT, form.current, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      })
      // .sendForm('service_yqxv2f8', 'template_vf0cdmn', form.current, {
      //     publicKey: 'tJm2YCnRH4F67MJpe',
      // })
      .then(
        () => {
          console.log('SUCCESS!');
          setIsPopUpOpen(true)
          setPopUpData({
            label: "Email Sent Successfully!!",
            type: ""
          })
          // setIsPopUpOpenText("Email Sent Successfully!!")
          // alert("Email Sent Successfully!!")
          e.target.reset();
          setIsLoaderOn(false)
        },
        (error) => {
          console.log('FAILED...', error.text);
          setIsPopUpOpen(true)
          setPopUpData({
            label: "Something Went Wrong!!",
            type: "err"
          })
          e.target.reset()
          setIsLoaderOn(false)
        },
      );
  };

  const handleLinkCopy = () => {
    navigator.clipboard.writeText('https://credolegal.com/')
    toast.success("Link Copied successfully!!")
  }
  return (
    <>
      {isLoaderOn && <Loader />}
      {isPopUpOpen &&
        <PopUP data={{ ...popUpData, setIsPopUpOpen }} />}
      <div className="banner15 padding-x flex flex-col md:flex-row gap-14 lg:gap-20 xl:gap-32">
        <div className="left md:w-1/2">
          <img src={img5} alt="" />
        </div>
        <form className="right relative md:w-1/2" ref={form} onSubmit={sendEmail}>
          <span className='absolute md:top-28 top-0 right-0 -translate-x-1/2 h-[6rem] md:h-[7.5rem] -z-10 opacity-60'>
            {stampLogo}
          </span>
          <h1 className='  text-red-500 text-center md:text-left National2CompresedXbold text-5xl mt-[2rem] md:mt-[4rem] md:text-7xl xl:text-[5.125rem]'>
            CONTACT US
          </h1>
          <p className='BasierSquareMedium text-sm mt-8 text-center md:text-left'>
            2151 W Hillsboro Blvd, Deerfield Beach, Fl 33442
          </p>
          {/* <p className='BasierSquareMedium text-sm text-center md:text-left'>
            (212) 920-1247
          </p> */}
          <p className='BasierSquareMedium text-sm underline decoration-red-500 text-center md:text-left'>
            Email Customer Support
          </p>


          <div className="form mt-[2rem] md:mt-[4rem] flex flex-col gap-4">
            {contactformData?.map((item, i) => {
              return <InputBox data={item} key={i} />
            })}
          </div>
          <div className='flex justify-center md:justify-end mt-6 md:mt-8 xl:mt-10'>
            <Button data={{
              label: "Submit"
            }} />
          </div>
        </form>

      </div>
      <div className=" padding-x md:mt-[6rem] mt-[4rem] xl:mt-[9rem]">
        <div className="banner16 relative py-10 px-14 bg-[#CDCFE5] flex ">
          <img src={banner16Img} alt="" className='absolute top-0 right-0 h-full opacity-60 z-10' />
          <div className="left w-full xl:w-1/2  gap-6 relative z-20">
            <h3 className='National2CompresedXbold text-center md:text-left text-4xl md:text-5xl xl:text-6xl text-[#1C0326]'>REFER US <br /> TO A FRIEND

            </h3>
            <p className='BasierSquareRegular md:text-2xl text-center md:text-left text-[#1C0326] mt-4'>
              Satisfied with your Credo Legal Experience?
              Share this valuable tool with your friends and family.
            </p>
            <div className='flex justify-center md:justify-start'>

              <Button
                onClick={() => { handleLinkCopy() }}
                data={{
                  label: "Share Link",
                  type: "black",
                  className: "mt-6 text-center md:text-left"
                }} />
            </div>
          </div>


        </div>

      </div>
    </>
  )
}

export default Contact