import React from 'react'
import Button from '../components/Button'
import { Link, useNavigate } from 'react-router-dom'
import Card4 from '../components/Card4'
import { banner8Data, banner9Data, stampLogo } from '../asset/staticData'
import Card5 from '../components/Card5'
import { motion } from "framer-motion"

function About() {
  const navigate = useNavigate()
  const textApperTopto = {
    initial: {
      x: -100,
      opacity: 0,
      // scale: 1.5
    },
    animate: {
      transition: {
        duration: 1.5
      },
      x: 0,
      // scale: 1,
      opacity: 1,
    }
  }
  return (
    <>
      <div className=" flex banner1 flex-row-reverse relative ">
        <span className='absolute bottom-0 left-1/2 -translate-x-1/2 h-[6rem] md:h-[7.5rem] z-40 opacity-60'>
          {stampLogo}
        </span>
        <div className="left about">

        </div>
        <div className="right bg-[#CDCFE5] pr-11 xl:pr-[3.625rem] padding-l  ">
          {/* <img src={banner1bottomright} alt="" className='absolute bottom-0 -right-[20%] sm:-right-[10%] h-2/3 opacity-30' /> */}
          {/* <h1 className='hidden lg:block text-red-500 National2CompresedXbold text-7xl xl:text-[5.125rem] leading-normal'>
            FIGHTING FOR <br /> <span className='px-3   bg-white'>
              FINANCIAL</span><span className='px-3  bg-white text-wrap'>FREEDOM
            </span>
          </h1> */}
          <motion.h1
            initial="initial"
            animate="animate"
            variants={textApperTopto}
            className='block text-center sm:text-left  text-[#1C0326] National2CompresedXbold text-7xl xl:text-[5.125rem] leading-normal'>
            {/* FIGHTING FOR <br /> <span className='px-3   bg-white'>
              FINANCIAL </span> */}
            <span className='px-3  bg-white text-wrap text-red-500'> FREEDOM
            </span> IS OUR GUIDING BELIEF.
          </motion.h1>
          <motion.p
            initial="initial"
            animate="animate"
            variants={textApperTopto}
            className='text-[#1C0326] text-center md:text-left BasierSquareRegular text-base mt-5'>
            We belief in your inherent right to pursue life, liberty, and joy, free of pursuit by relentless creditors. We belief that hardworking Americans should prevail over their hardships. We belief a better future can be yours.
          </motion.p>
          <motion.div
            initial="initial"
            animate="animate"
            variants={textApperTopto}
            className='mt-10 sm:flex-row flex-col flex items-center justify-between'>

            <p className='DecimalBook text-xl text-center md:text-left'>
              Belief it too, because we belief in you.
            </p>
          </motion.div>
        </div>
      </div>
      <div className="banner8 padding-x flex flex-col items-center mt-[4rem] md:mt-[6rem] xl:mt-[8.75rem]">
        {banner8Data?.map((item, i) => {
          return <Card4 data={item} key={i} />
        })}
      </div>
      <div className="banner9 padding-x mt-[4rem] md:mt-[6rem] xl:mt-[8.75rem]">
        {banner9Data?.map((item, i) => {
          return <Card5 data={item} key={i} />
        })}
      </div>
      <div className="banner10 mt-[4rem] md:mt-[6rem] xl:mt-[8.75rem] flex items-center flex-col">
        <h3 className='National2CompresedXbold text-[#1C0326] leading-[3.5rem] xl:leading-[4.5rem] text-center text-5xl xl:text-6xl'>
          THE SECRET TO GETTING AHEAD
          <br />  IS <span className='text-white bg-[#CDCFE5] px-4 '>
            GETTING STARTED.
          </span>
        </h3>
        <div className='mt-6 xl:mt-8'>

          <Button data={{
            label: "Book a Consultation",
            onClickFunc: () => {
              navigate("../ContactUs")
            }
          }} />
        </div>
      </div>
    </>
  )
}

export default About