import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { animate, inView, motion, transform, useScroll } from "framer-motion"
import { useEffect } from 'react';

function Card1({ data }) {
    let { icon, label, para, i } = data;
    const ref = useRef(null)
    // const { scrollYProgress } = useScroll({
    //     target: ref,
    //     offset: ["start end", "end end"]
    // })
    // console.log(scrollYProgress);
    // const BOxApperBottomto = {
    //     initial: {
    //         // y: 100,
    //         opacity: 0,
    //         // scale: 1
    //         // scale: 1.2
    //     },
    //     animate: (i) => ({
    //         // y: 0.1,
    //         // scale: 1,
    //         opacity: 1,


    //         transition: {
    //             duration: 0.8,
    //             delay: 0.5 * i,
    //             // type: "tween"
    //             // ease: "easeInOut",
    //             // repeatType: "reverse",
    //             // repeat: 1,

    //         },

    //     })
    // }

    const [isInViewPort, setIsInViewPort] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', () => {
            console.log(isInViewPort);
            let allElements = ref.current;
            // let allElements = document.getElementsByTagName(elem);

            let windowHeight = window.innerHeight;

            let viewportOffset = allElements?.getBoundingClientRect();
            console.log(i, viewportOffset, windowHeight); //  returns the size of an element and its position relative to the viewport
            let top = viewportOffset.top;  //  get the offset top
            let bottom = viewportOffset.bottom;  //  get the offset top
            if ((top < windowHeight && top > 0) || (bottom > 0 && bottom < windowHeight)) {  //  if the top offset is less than the window height
                // allElements[i].classList.add('in-viewport');  
                setIsInViewPort(true)//  add the class
            } else {
                // allElements[i].classList.remove('in-viewport');
                setIsInViewPort(false)  //  remove the class
            }
            // const elems = () => {
            //     for (let i = 0; i < allElements.length; i++) {  //  loop through the sections

            //     }
            // }
            // elems();

        });
    }, [isInViewPort])
    // const inViewport = 

    // inViewport('section');  //  run the function on all section elements



    return (
        <>
            <div
                // variants={BOxApperBottomto}
                // viewport={{ once: true }}
                // initial="initial"
                // whileInView="animate"
                ref={ref}
                custom={i}
                // whileHover={{
                //     scale: 1.05,
                //    transition:{
                //     duration:.5,
                //     ease:"linear"
                //    }
                // }}



                style={{
                    animationDelay: `${300 * i}ms`
                }}
                // xl:w-[17.25rem]
                className={` opacity-0 hoverScale  p-6 transition-all   bg-[#F5F6FA] flex flex-col justify-between duration-1000 ${isInViewPort ? "arrowForward" : ""}`}>
                <div className="top">
                    {/* w-[5.625rem] */}
                    <div className='flex items-center h-[5.725rem]'>

                        {icon}
                    </div>
                    <h4 className='text-red-500 DecimalMedium text-lg mt-5'>
                        {label}
                    </h4>
                    <p className='mt-5 BasierSquareRegular text-sm'>
                        {para}
                    </p>
                </div>

                <Link to={"Services"} className='BasierSquareMedium mt-5 underline text-sm decoration-red-500  underline-offset-4'>
                    Learn More
                </Link>
            </div>
        </>
    )
}

export default Card1