
import { useState } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AppRouter from "./routes/AppRouter";
import { Toaster } from 'react-hot-toast';

function App() {

  const [isNavbarOpen, setIsNavbarOpen] = useState(false)
  return (
    <div className={`h-screen flex items-center justify-center`}>
      <div className={`${isNavbarOpen ? "overflow-hidden" : ""} max-w h-full`}>

        <Toaster />
        <Navbar data={{ isNavbarOpen, setIsNavbarOpen }} />

        <AppRouter />
        {/* banner 5 */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
