import React from 'react'

function Card5({ data }) {
    let { img, label, para } = data;
    return (
        <>
            <div className='md:flex-row flex-col flex items-center gap-5 card5'>
                <div className="left h-[12rem] md:h-[17rem] w-full  md:w-1/3" style={{
                    backgroundImage:  `url(${img})`,

                }}>

                </div>
                <div className="right w-full md:w-2/3">
                    <h3 className='National2CompresedXbold text-center md:text-left text-5xl text-[#1C0326]'>
                        {label}
                    </h3>
                    <p className='BasierSquareRegular text-base text-center md:text-left text-[#1C0326]'>
                        {para}
                    </p>
                </div>
            </div>
        </>
    )
}

export default Card5