import React from 'react'
import Card6 from '../components/Card6'
import { banner13Data } from '../asset/staticData';
import bird from "../images/bird.png"
import Button from '../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';

function Faq() {
  const navigate=useNavigate();
  return (
    <>
      <div className="banner13 mt-[2rem] md:mt-[4rem] padding-x flex flex-col items-center">
        <h1 className='text-center  text-red-500 National2CompresedXbold text-5xl md:text-7xl xl:text-[5.125rem] '>
          FREQUENTLY ASKED QUESTION
        </h1>
        <div className='flex flex-col mt-[1rem] md:mt-[3rem] w-full md:w-2/3 xl:w-1/2 gap-5'>

          {banner13Data?.map((item, i) => {
            return <Card6 data={item} key={i} />
          })}
        </div>
      </div>
      <div className="banner14 padding-x flex flex-col items-center mt-[2rem] md:mt-[4rem]">
        <img src={bird} alt="" />
        <h3 className='text-center National2CompresedXbold text-4xl xl:text-5xl'>
          DON'T SEE YOUR QUESTION HERE?
        </h3>
        <Button data={{
          label: "Contact us here",
          onClickFunc:()=>{
            navigate("../ContactUs")
          },
          className:"mt-6"
        }}/>
      </div>
    </>
  )
}

export default Faq