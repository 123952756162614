import React from 'react'

function Button({ data, onClick }) {
    let { label, type, onClickFunc, className } = data;
    return (
        <>
            <button onClick={() => {
                if (onClickFunc) {

                    onClickFunc()
                }
                if (onClick) {
                    onClick()
                }
            }} className={`${type == "black" ? "bg-[#1C0326]" : "bg-red-500"} flex justify-between  ${className} items-center px-3 h-10 w-[13rem] xl:w-[14.375rem] text-white text-sm xl:text-base DecimalMedium`}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                </svg>

                <span>

                    {label}
                </span>
            </button>
        </>
    )
}

export default Button