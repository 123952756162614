import React from 'react'

const CoockiePolicy = () => {
    return (
        <div className='max-w-5xl mx-auto p-5 md:p-10 flex flex-col space-y-2'>
            <h1 className='text-3xl font-medium '>Coockie Policy</h1>
            <p className='mt-2'>This document informs Users about the technologies that help this Website to achieve the purposes described below. Such technologies allow the Owner to access and store information (for example by using a Cookie) or use resources (for example by running a script) on a User’s device as they interact with this Website.
            </p>
            <p className='mt-2'>For simplicity, all such technologies are defined as “Trackers” within this document – unless there is a reason to differentiate.
            </p>
            <p className='mt-2'>For example, while Cookies can be used on both web and mobile browsers, it would be inaccurate to talk about Cookies in the context of mobile apps as they are a browser-based Tracker. For this reason, within this document, the term Cookies is only used where it is specifically meant to indicate that particular type of Tracker.
            </p>
            <p className='mt-2'>Some of the purposes for which Trackers are used may also require the User’s consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document.
            </p>
            <p className='mt-2'>This Website uses Trackers managed directly by the Owner (so-called “first-party” Trackers) and Trackers that enable services provided by a third-party (so-called “third-party” Trackers). Unless otherwise specified within this document, third-party providers may access the Trackers managed by them.
            </p>
            <p className='mt-2'>The validity and expiration periods of Cookies and other similar Trackers may vary depending on the lifetime set by the Owner or the relevant provider. Some of them expire upon termination of the User’s browsing session.
            </p>
            <p className='mt-2'>In addition to what’s specified in the descriptions within each of the categories below, Users may find more precise and updated information regarding lifetime specification as well as any other relevant information – such as the presence of other Trackers – in the linked privacy policies of the respective third-party providers or by contacting the Owner.
            </p>
            <p className='mt-2'>To find more information dedicated to consumers and their privacy rights, Users may <a className='underline text-blue-500' href='https://consumerlegalgroup.com/privacy-policy/' target='_blank' rel='noreferrer'> read the privacy policy.</a>
            </p>
            <h2 className='text-xl font-medium mt-5'>Activities strictly necessary for the operation of this Website and delivery of the Service</h2>
            <p className='mt-2'>This Website uses so-called “technical” Cookies and other similar Trackers to carry out activities that are strictly necessary for the operation or delivery of the Service.
            </p>
            <h3 className='mt-2 text-lg underline'>Third-party Trackers
            </h3>
            <ul className='list-disc ml-5'>
                <li>Tag Management</li>
                <li>This type of service helps the Owner to manage the tags or scripts needed on this Website in a centralized fashion.
                </li>
                <li>This results in the Users’ Data flowing through these services, potentially resulting in the retention of this Data.</li>
                <li>Google Tag Manager (Google LLC)</li>
                <li>Google Tag Manager is a tag management service provided by Google LLC.
                </li>
                <li>Personal Data processed: Tracker and Usage Data.</li>
                <li>Place of processing: United States – <a href='https://policies.google.com/privacy' target='_blank' rel='noreferrer' >Privacy Policy.</a>
                </li>
            </ul>
            <h2 className='text-xl font-medium mt-5'>Other activities involving the use of Trackers
            </h2>
            <p>Basic interactions & functionalities
            </p>
            <p className='mt-2'>This Website uses Trackers to enable basic interactions and functionalities, allowing Users to access selected features of the Service and facilitating the User’s communication with the Owner.</p>
            <h2 className='text-xl font-medium mt-5'>How to manage preferences and provide or withdraw consent </h2>
            <p className='mt-2'>There are various ways to manage Tracker related preferences and to provide and withdraw consent, where relevant:
            </p>
            <p className='mt-2'>Users can manage preferences related to Trackers from directly within their own device settings, for example, by preventing the use or storage of Trackers.
            </p>
            <p className='mt-2'>Additionally, whenever the use of Trackers is based on consent, Users can provide or withdraw such consent by setting their preferences within the cookie notice or by updating such preferences accordingly via the relevant consent-preferences widget, if available.
            </p>
            <p className='mt-2'>It is also possible, via relevant browser or device features, to delete previously stored Trackers, including those used to remember the User’s initial consent.
            </p>
            <p className='mt-2'>Other Trackers in the browser’s local memory may be cleared by deleting the browsing history.
            </p>
            <p className='mt-2'>With regard to any third-party Trackers, Users can manage their preferences and withdraw their consent via the related opt-out link (where provided), by using the means indicated in the third party’s privacy policy, or by contacting the third party.
            </p>
            <h3 className='mt-2 text-lg underline'>Locating Tracker Settings
            </h3>
            <p className='mt-2'>Users can, for example, find information about how to manage Cookies in the most commonly used browsers at the following addresses:
            </p>
            <ul className='list-disc ml-5'>
                <li><a className='underline text-blue-500' href='https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies' target='_blank' rel='noreferrer'>Google Chrome</a></li>
                <li><a className='underline text-blue-500' href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences' target='_blank' rel='noreferrer'>Mozilla Firefox</a></li>

                <li><a className='underline text-blue-500' href='https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/' target='_blank' rel='noreferrer'>Apple Safari</a></li>
                <li><a className='underline text-blue-500' href='http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies' target='_blank' rel='noreferrer'>Microsoft Internet Explorer</a></li>
                <li><a className='underline text-blue-500' href='https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing' target='_blank' rel='noreferrer'>Brave</a></li>

                <li><a className='underline text-blue-500' href='https://consumerlegalgroup.com/cookie-policy.html#cookies' target='_blank' rel='noreferrer'>Opera</a></li>

            </ul>
            <p className='mt-2'>Users may also manage certain categories of Trackers used on mobile apps by opting out through relevant device settings such as the device advertising settings for mobile devices, or tracking settings in general (Users may open the device settings and look for the relevant setting).
            </p>
            <h3 className='mt-2 text-lg underline'>How to opt out of interest-based advertising</h3>
            <p className='mt-2'>Notwithstanding the above, Users may follow the instructions provided by <a className='underline text-blue-500' href='http://www.youronlinechoices.eu/' target='_blank' rel='noreferrer'> YourOnlineChoices </a>(EU), the <a className='underline text-blue-500' href='https://thenai.org/about-online-advertising/' target='_blank' rel='noreferrer'> Network Advertising Initiative</a> (US) and the <a className='underline text-blue-500' href='https://thenai.org/about-online-advertising/' target='_blank' rel='noreferrer'>Digital Advertising Alliance </a> (US),<a className='underline text-blue-500' href='https://youradchoices.ca/understanding-online-advertising/' target='_blank' rel='noreferrer'> DAAC </a> (Canada), <a className='underline text-blue-500' href='http://www.ddai.info/optout' target='_blank' rel='noreferrer'> DDAI  </a>(Japan) or other similar services. Such initiatives allow Users to select their tracking preferences for most of the advertising tools. The Owner thus recommends that Users make use of these resources in addition to the information provided in this document.
            </p>
            <p className='mt-2'>The Digital Advertising Alliance offers an application called <a className='underline text-blue-500' href='https://youradchoices.com/appchoices' target='_blank' rel='noreferrer'>AppChoices</a> that helps Users to control interest-based advertising on mobile apps.</p>
            <h3 className='mt-2 text-lg underline'>Consequences of denying consent
            </h3>
            <p className='mt-2'>Users are free to decide whether or not to grant consent. However, please note that Trackers help this Website to provide a better experience and advanced functionalities to Users (in line with the purposes outlined in this document). Therefore, in the absence of the User’s consent, the Owner may be unable to provide related features.
            </p>
            <h2 className='text-xl font-medium mt-5'>Owner and Data Controller
            </h2>
            <p className='mt-1'>Credo Legal
            </p>
            <p className='mt-2'>2151 W Hillsboro Blvd, Deerfield Beach, Fl 33442</p>
            <p className='mt-2'>Owner contact email: <a className='underline text-blue-500' target='_blank' rel='noreferrer' href='mailto:support@credolegal.com'>support@credolegal.com</a> </p>
            <p className='mt-2'>Since the use of third-party Trackers through this Website cannot be fully controlled by the Owner, any specific references to third-party Trackers are to be considered indicative. In order to obtain complete information, Users are kindly requested to consult the privacy policies of the respective third-party services listed in this document.</p>
            <p className='mt-2'>Given the objective complexity surrounding tracking technologies, Users are encouraged to contact the Owner should they wish to receive any further information on the use of such technologies by this Website.
            </p>
            <h2 className='text-xl font-medium mt-5'>Definitions and legal references</h2>
            <h3 className='mt-2 text-lg underline'>Personal Data (or Data)</h3>
            <p className='mt-2'>Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
            </p>
            <h3 className='mt-2 text-lg underline'>Usage Data</h3>
            <p className='mt-2'>Information collected automatically through this Website (or third-party services employed in this Website), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server’s answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User’s IT environment.
            </p>
            <h3 className='mt-2 text-lg underline'>User</h3>
            <p className='mt-2'>The individual using this Website who, unless otherwise specified, coincides with the Data Subject.
            </p>
            <h3 className='mt-2 text-lg underline'>Data Subject</h3>
            <p className='mt-2'>The natural person to whom the Personal Data refers.</p>
            <h3 className='mt-2 text-lg underline'>Data Processor (or Data Supervisor)</h3>
            <p className='mt-2'>The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
            </p>
            <h3 className='mt-2 text-lg underline'>Data Controller (or Owner)</h3>
            <p className='mt-2'>The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Website. The Data Controller, unless otherwise specified, is the Owner of this Website.
            </p>
            <h3 className='mt-2 text-lg underline'>This Website (or this Application)</h3>
            <p className='mt-2'>The means by which the Personal Data of the User is collected and processed.
            </p>
            <h3 className='mt-2 text-lg underline'>Service</h3>
            <p className='mt-2'>The service provided by this Website as described in the relative terms (if available) and on this site/application.
            </p>
            <h3 className='mt-2 text-lg underline'>Cookie</h3>
            <p className='mt-2'>Cookies are Trackers consisting of small sets of data stored in the User’s browser.
            </p>
            <h3 className='mt-2 text-lg underline'>Tracker</h3>
            <p className='mt-2'>Tracker indicates any technology – e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting – that enables the tracking of Users, for example by accessing or storing information on the User’s device.
            </p>
            <h3 className='mt-2 text-lg underline'>Legal information</h3>
            <p className='mt-2'>This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
            </p>
            <p className='mt-2'>This privacy policy relates solely to this Website, if not stated otherwise within this document.
            </p>
        </div>

    )
}

export default CoockiePolicy