import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { logo } from '../asset/staticData';


function Navbar({ data }) {
    const { isNavbarOpen, setIsNavbarOpen } = data;
    const location = useLocation();
    useEffect(() => {
        setIsNavbarOpen(false)
        // console.log(location?.pathname.toLowerCase() == "/aboutus");
        window.scrollTo(0, 0);
    }, [location])

    return (
        <>
            {isNavbarOpen && <div className=' xl:hidden absolute h-full w-full left-0   z-40 bg-white  bg-opacity-90 items-center gap-6 py-10 flex flex-col  navlinks top-[4.8rem]'>
                <div className="navlinks flex flex-col  items-center justify-between gap-9 ">
                    <Link className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/" ? "text-red-500" : "text-[#070606]"}`} to={"/"}>
                        Home
                    </Link>
                    <Link className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/aboutus" ? "text-red-500" : "text-[#070606]"}`} to={"/About"}>
                        About
                    </Link>
                    <Link className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/services" ? "text-red-500" : "text-[#070606]"}`} to={"/Services"}>
                        Services
                    </Link>
                    <Link className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/faq" ? "text-red-500" : "text-[#070606]"}`} to={"/FAQ"}>
                        FAQ
                    </Link>
                    <Link className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/contactus" ? "text-red-500" : "text-[#070606]"}`} to={"/ContactUs"}>
                        Contact Us
                    </Link>
                </div>
                <div className="right  items-center gap-5 flex flex-col mt-16">
                    <button className='DecimalMedium border-2 px-5 py-2 rounded-full border-red-500 text-red-500'>
                        Sign Up
                    </button>
                </div>
            </div>}



            <nav className='h-[4.8rem] z-50  sticky top-0 bg-white shadow-md  w-full padding-x flex items-center justify-between'>
                <Link to={"/"} className="left flex items-center">
                    {logo}

                </Link>
                {/* hamburger btn */}
                <button onClick={(() => {
                    setIsNavbarOpen(!isNavbarOpen)
                })} className="smallLogo  h-10 w-10 flex xl:hidden flex-shrink-0 items-center justify-center rounded-md text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#070606" className="h-full w-full text-[#070606]">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>

                </button>
                <div className="navlinks  items-center justify-between gap-9 hidden xl:flex">
                    <Link to={"/"} className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/" ? "text-red-500" : "text-[#070606]"}`}>
                        Home
                    </Link>
                    <Link to={"/About"} className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/about" ? "text-red-500" : "text-[#070606]"}`}>
                        About
                    </Link>
                    <Link to={"/Services"} className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/services" ? "text-red-500" : "text-[#070606]"}`}>
                        Services
                    </Link>
                    <Link to={"/FAQ"} className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/faq" ? "text-red-500" : "text-[#070606]"}`}>
                        FAQ
                    </Link>
                    <Link to={"/ContactUs"} className={`DecimalBook text-base ${location?.pathname.toLowerCase() == "/contactus" ? "text-red-500" : "text-[#070606]"}`}>
                        Contact Us
                    </Link>
                </div>
                <div className="right  items-center gap-5 hidden xl:flex">
                    <Link to={"/ContactUs"} className='DecimalMedium border-2 px-5 py-2 rounded-full border-red-500 text-red-500'>
                        Sign Up
                    </Link>
                </div>
            </nav>
        </>
    )
}

export default Navbar;