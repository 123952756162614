import React from 'react'

function InputBox({ data }) {
    let { label, placeholder, icon, formLabel, type, options } = data
    return (
        <>
            <div className="inputbox border border-[#CDCFE5] flex items-center justify-between  py-4 px-9 ">
                <div className="left w-full">

                    <label className="capitalize BasierSquareRegular text-xs text-[#928A83]">
                        {label}
                    </label>
                    {type == "select" ?
                        <select type="text" name={formLabel} className='w-full capitalize focus:outline-none DecimalMedium text-sm md:text-lg text-[#070606]' defaultValue={"none"} placeholder={placeholder} >
                            {options?.map(({label,value}, i) => {
                                return <option key={i} value={value}>
                                    {label}
                                </option>
                            })}
                        </select>

                        :

                        <input type="text" name={formLabel} className='w-full capitalize focus:outline-none DecimalMedium text-sm md:text-lg text-[#070606]' placeholder={placeholder} />}
                </div>
                <div className='right h-7 w-7'>
                    {icon}

                </div>

            </div>
        </>
    )
}

export default InputBox