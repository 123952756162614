import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import Card1 from '../components/Card1';
import { banner2Data, banner3Data, banner4Data, banner5Data } from '../asset/staticData';
import Card2 from '../components/Card2';
import Card3 from '../components/Card3';
import banner1bottomright from "../images/banner1bottomright.png"
import Carousel from 'react-elastic-carousel';
import { motion } from "framer-motion"


function Home() {
  const navigate = useNavigate();
  const textApperTopto = {
    initial: {
      x: 100,
      opacity: 0,
      // scale: 1.5
    },
    animate: {
      transition: {
        duration: 1.5
      },
      x: 0,
      // scale: 1,
      opacity: 1,
    }
  }
  const BOxApperBottomto = {
    initial: {
      y: 100,
      opacity: 0,
      // scale: 1
      // scale: 1.2
    },
    animate: (i) => ({
      y: 0.1,
      // scale: 1,
      opacity: 1,


      transition: {
        duration: 1.5,
        delay: 0.5 * i,
        // type: "tween"
        // ease: "easeInOut",
        // repeatType: "reverse",
        // repeat: 1,

      },

    })
  }

  return (
    // <motion.Fragment initial={{ x: "-100vw" }}
    //   animate={{
    //     x: 0, transition: {
    //       duration: 1.5
    //     }
    //   }}
    //   exit={{ x: "-100vw" }}
    // >
    <>





      <div className=" flex banner1">
        <div className="left home">

        </div>
        <motion.div
          // transition={{duration:3}}

          className="right bg-[#1C0326] pl-11 xl:pl-[3.625rem] padding-r">
          <img src={banner1bottomright} alt="" className='absolute z-10 bottom-0 -right-[20%] sm:-right-[10%] h-2/3 opacity-30' />
          {/* <h1 className='hidden lg:block text-red-500 National2CompresedXbold text-7xl xl:text-[5.125rem] leading-normal'>
            FIGHTING FOR <br /> <span className='px-3   bg-white'>
              FINANCIAL</span><span className='px-3  bg-white text-wrap'>FREEDOM
            </span>
          </h1> */}
          <motion.h1 initial="initial"
            animate="animate"
            variants={textApperTopto}
            className='block text-center sm:text-left  text-red-500 National2CompresedXbold text-7xl xl:text-[5.125rem] leading-normal'>
            FIGHTING FOR <br /> <span className='px-3   bg-white'>
              FINANCIAL </span>
            <span className='px-3  bg-white text-wrap'> FREEDOM
            </span>
          </motion.h1>
          <motion.p initial="initial"
            animate="animate"
            variants={textApperTopto}
            className='text-white BasierSquareRegular text-center sm:text-left text-base mt-5'>
            Facing financial legal issues? It’s time to fight back.
            Credo Legal is fiercely committed to defending and protecting your rights. We belief a better future can be yours.
          </motion.p>
          <motion.div initial="initial"
            animate="animate"
            variants={textApperTopto} className='mt-10 relative z-20 sm:flex-row flex-col flex items-center justify-between'>

            <Button data={{
              label: "Book a Consultation",
              onClickFunc: () => {
                navigate("../ContactUs")
              }
            }} />

            <Link initial="initial"
              to={"../Services"}
              animate="animate"
              variants={textApperTopto}
              className="DecimalMedium mt-4 text-sm xl:text-base underline text-white">
              View Our Services
            </Link>
          </motion.div>
        </motion.div>
      </div>
      <div className="banner2 padding-x">
        <h3 className='National2CompresedXbold text-center sm:text-left text-5xl xl:text-6xl text-red-500 '>
          OUR SERVICES
        </h3>

        <div className="services gap-4 h-max ">
          {banner2Data?.map((data, i) => {
            return <Card1 data={{ ...data, i }} key={i} />
          })}
        </div>
      </div>
      <div className="banner3 xl:mt-[7.9rem] sm:mt-[6rem] mt-[4rem] bg-[#5F081A] h-[27.75rem]">
        <h3 className='National2CompresedXbold text-5xl sm:text-6xl xl:text-7xl text-red-500'>
          TAKE THE FIRST STEPS
        </h3>
        <p className='text-white DecimalBook text-xl sm:text-2xl mt-2'>
          Enrollment is easy!
        </p>
        <div className='grid grid-cols-3 sm:flex md:w-4/6 2xl:w-1/2 mt-[4rem] padding-x gap-3 '>
          {banner3Data?.map(({ icon, label }, i) => {
            return <div className={`flex items-center flex-col sm:flex-row gap-2  `} key={i}>
              <div
                // variants={BOxApperBottomto}
                // initial="initial"
                // whileInView="animate"
                custom={i} className='h-[7.25rem]  w-[3.875rem]  flex-shrink-0'>
                {icon}
              </div>
              <p className='text-white BasierSquareRegular text-sm sm:text-base'>
                {label}
              </p>
            </div>
          })}
        </div>

      </div>
      <div className="banner4 padding-x mt-[9.25rem]">
        <div className='md:px-8 xl:px-16 w-full'>

          {banner4Data?.map((item, i) => {
            return <Card2 data={{ ...item, i }} key={i} />
          })}
        </div>
      </div>

      <div className="banner5 flex flex-col items-center mt-[6rem] xl:mt-[9.25rem]">
        <h3 className='National2CompresedXbold text-[#1C0326] text-center text-5xl xl:text-6xl'>
          SUCCESSFUL CLIENTS SPEAK
        </h3>

        <Carousel breakPoints={[
          {
            width: 1,
            itemsToShow: 1
          },

          {
            width: 1024,
            itemsToShow: 2
          },
        ]} className='carosol flex gap-4 justify-center w-full padding-x mt-14 h-max'>

          {/* <div > */}
          {banner5Data?.map((item, i) => {
            return <Card3 data={item} />
          })}
          {/* </div> */}
        </Carousel>
      </div>

      {/* </motion.Fragment> */}
    </>
  )
}

export default Home;