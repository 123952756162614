import React from 'react'
import { motion } from "framer-motion"

function Card3({ data }) {
    let { img, name, label, para, rattingStar, i } = data;
    let arr = [0, 0, 0, 0, 0];
    const BOxApperBottomto = {
        initial: {
            // y: 100,
            opacity: 0,
            // scale: 1
            // scale: 1.2
        },
        animate: {
            // y: 0.1,
            // scale: 1,
            opacity: 1,


            transition: {
                duration: 1.5,
                // type: "tween"
                // ease: "easeInOut",
                // repeatType: "reverse",
                // repeat: 1,

            }

        }
    }

    return (
        <>
            <motion.div
                variants={BOxApperBottomto}
                // viewport={{ once: true }}
                initial="initial"
                whileInView="animate"
                className=' w-[34.345rem] md:w-full h-full  relative bg-[#E7E9F3] px-10 py-6 overflow-hidden'>
                <span className='absolute top-0 right-0 px-10 py-6 flex'>
                    {arr?.map((item, i) => {
                        return <svg xmlns="http://www.w3.org/2000/svg" width="19.035" height="18.1" viewBox="0 0 19.035 18.1" >
                            <path id="Path_1454" data-name="Path 1454" d="M808.824,1659.01l2.247,6.91h7.271l-5.882,4.28,2.247,6.91-5.883-4.27-5.882,4.27,2.247-6.91-5.882-4.28h7.271l2.246-6.91Z" transform="translate(-799.307 -1659.01)" fill={`${i + 1 > rattingStar ? "white" : "#f81726"}`} strokeWidth='1px' stroke='#f81726' />
                        </svg>

                    })}
                </span>
                <div className="top flex items-center">
                    <img src={img} alt="" className='h-16 w-16 rounded-full' />
                    <p className='BasierSquareSemibold text-base text-[#070606] ml-5'>{name}</p>
                </div>
                <div className="bot h-full mt-7">

                    <p className='DecimalMedium text-xl text-[#5F081A] '>
                        {label}
                    </p>
                    <p className='BasierSquareRegular mt-4 text-base text-[#070606]'>
                        {para}
                    </p>
                </div>
            </motion.div>
        </>
    )
}

export default Card3