import React, { useState } from 'react'

export default function Card6({ data }) {
    let { ques, ans } = data;
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <div onClick={() => {
                setIsOpen(!isOpen)
            }} className={`py-7 cursor-pointer  px-10 border border-[#CDCFE5] transition-all  ${isOpen ? "bg-[#ECEFF6]" : ""}`}>
                <div className="top flex items-center justify-between">
                    <p className='text-[#070606] text-lg DecimalMedium'>
                        {ques}
                    </p>
                    <button className='h-9 w-9 flex items-center justify-center' >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-6 h-6 transition-all ${isOpen ? "rotate-180" : ""}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                        </svg>

                    </button>
                </div>
                <div className={`ans text-[#070606] overflow-hidden text-base BasierSquareRegular transition-all ${isOpen ? "h-fit" : "h-0"}`}>
                    {ans}
                </div>

            </div>
        </>
    )
}
