import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from '../pages/Home'
import About from '../pages/About'
import Services from '../pages/Services'
import Faq from '../pages/Faq'
import Contact from '../pages/Contact'
import { AnimatePresence } from 'framer-motion'
import TermsOfService from '../pages/TermsOfService'
import PrivacyPolicy from '../pages/PrivacyPolicy'
import CoockiePolicy from '../pages/CoockiePolicy'
// import Sarvice from '../pages/Sarvice'
// import Contact from '../pages/Contact'

function AppRouter() {
  let location = useLocation()
  return (
    <>
      <AnimatePresence>


        <Routes location={location} key={location.pathname}>
          <Route exact={true} path="/" element={<Home />} />
          <Route exact={true} path="/About" element={<About />} />
          <Route exact={true} path="/Services" element={<Services />} />
          <Route exact={true} path="/FAQ" element={<Faq />} />
          <Route exact={true} path="/ContactUs" element={<Contact />} />
          <Route exact={true} path="/term-of-service" element={<TermsOfService />} />
          <Route exact={true} path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact={true} path="/cookie-policy" element={<CoockiePolicy />} />
        </Routes>
      </AnimatePresence>
    </>
  )
}

export default AppRouter